import React from 'react';
// import { Flyin, Div, Span, Wrapper} from './styles';
import {WrapperType, Line, Bounce, Arrow, DownArrow } from './styles';
import arrow from '../../assets/down-arrow.svg';
import { Link } from 'react-scroll';

function Trailer(){
  
  return(
    // <Wrapper id='home'>    
    //   <Flyin>
    //     <Div delay={4}>JCastro<Span>A sua consultoria em Licitações</Span></Div>
    //     <Div delay={8}>Estamos Trabalhando<Span>Para melhor lhe servir</Span></Div>
    //     <Div delay={12}>JCastro Constultoria em Licitações</Div>
    //     <Div delay={16}>Fale com a gente<Span>jaheb@jcastro.adv.br</Span></Div>
    //   </Flyin>
    // </Wrapper>

    <WrapperType id='home'>
      <Line>
          JCastro Consultoria em Licitações
      </Line>
      <Bounce>
      <DownArrow>
        <Link to='about' smooth={true}><Arrow alt="" src={arrow}/></Link>
      </DownArrow>
    </Bounce>
    </WrapperType>
  );
}

export default Trailer;