import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  background-color: var(--color-black-less);
  background-image: radial-gradient(var(--color-grey), var(--color-black-less));
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* color: var(--color-gold); */
  color: var(--color-white-light);
  /* color: var(--color-black-less); */
  text-align: center;
  /* width: 100vw; */
  height: 100vh;
  font-weight: 700;
  line-height: 1.5;
  overflow: hidden;
  font-family: 'Cinzel', serif;
`;

export const Flyin = styled.div`
  font-size: 40em;
  margin: 35vh auto;
  height: 25vh; 
  /* text-transform: uppercase; */

  @media(max-width: 887px){
    font-size: 30em;
    margin: 35vh auto;
    height: 25vh; 
    /* text-transform: uppercase; */
  }  
`;

export const Span = styled.span`
  display: block;
  font-size: .4em;
  opacity: .8;
`;

export const Switch = keyframes`
  0% { opacity: 0;filter: blur(20px); transform:scale(12);}
  3% { opacity: 1;filter: blur(0); transform:scale(1);}
  10% { opacity: 1;filter: blur(0); transform:scale(.9);}
  13% { opacity: 0;filter: blur(10px); transform:scale(.1);}
  80% { opacity: 0;}
  100% { opacity: 0;}
`;

export const Div = styled.div`
  /* position: absolute; */
  margin: 2vh 0;
  opacity: 0;
  left: 10vw;
  width: 80vw;
  animation: ${Switch} 20s linear infinite;
  animation-delay: ${({ delay }) => delay }s;
`;

export const WrapperType = styled.div`
  height: 100vh;
  padding: 4em;
  /* color: rgba(255,255,255,.75); */
  color: var(--color-gold);
  font-size: 40rem;
  font-family: 'Cinzel', monospace;
  background-color: rgb(25,25,25);
  overflow: hidden;
  
  @media(max-width: 887px){
    font-size: 18rem;
    padding: 0;
    /* margin: 15vh auto; */
    /* height: 45vh;  */
    /* text-transform: uppercase; */
  }
`;

export const Typewriter = keyframes`
  from{width: 0;}
  to{width: 19.5em;}
`;

export const BlinkTextCursor = keyframes`
  from{border-right-color: var(--color-gold);}
  to{border-right-color: transparent;}
`;

export const Line = styled.h1`
  position: relative;
  top: 50%;  
  width: 22em;
  margin: 0 auto;
  /* border-right: 2px solid rgba(255,255,255,.75); */
  border-right: 2px solid var(--color-gold);
  /* font-size: 150%; */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  /* transform: translateY(-50%);     */
  animation: ${Typewriter} 5s steps(44) 1s normal both infinite,
             ${BlinkTextCursor} 500ms steps(44) 2s infinite normal;

  @media(max-width: 887px){
    /* top: 35vh; */
    font-size: 100%;
    width: 20em;
  }
`;

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
`;

export const DownArrow = styled.div`
	position: fixed;
	bottom: 40%;
	left: 50%;

  @media(max-width: 887px){
    bottom: 20%;
  }
`;

export const Bounce = styled.div`
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
  height:100vh;
  /* background-color: var(--color-white-light); */
  animation: ${bounce} 3s infinite;
`;

export const Arrow = styled.img`
  width: 40px; 
  height: 40px;
  cursor: pointer;
  
  @media(max-width: 887px){
    width: 20px;
    height: 20px;
  } 
`;
